.container {
    background-color: var(--background-color);
    display: flex;
    flex-direction: column;
}

.container h1{
    color: var(--primary);
    align-self: center;
    margin: 1rem 0;
}

.bookNowBtnStyle{
    align-self: center;
    margin: 2vh 0;
}

.footerStyle {
    background-color: var(--secondary);
  }

@media (max-width: 768px) {
    .container {

    }

}
