.wrapper {
  padding: 1vh 0 3vh 0;
  display: flex;
  flex-direction: column;
  background-color: var(--background-color);
}

.title {
  margin-top: 1vh;
  text-align: center;
  font-family: Rumble_Brave, sans-serif;
  color: var(--primary);
  font-size: 4rem;
}

.servicesRow {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  margin: 5rem 6vw;
  user-select: none;
}

.serviceItem {
  flex-direction: "column";
  padding-bottom: 2vh;
  width: clamp(16rem ,16vw , 17rem);
}

.serviceItem h2{
  color: #000;
  margin-bottom: 10px;
}

.serviceItem h4 {
  color: #000;
}

.serviceItemTextContainer {
  background-color: var(--secondary);
  padding: 2vh 1vw;
  height: clamp(12rem, 60%, 12rem);
}

.serviceImg {
  height: clamp(16rem ,20vh , 22rem);
  display: block;
  object-fit: cover;
  border-top-left-radius: 100%;
  border-top-right-radius: 100%;
  border: 1px solid black;
  background-color: black;
}

.priceListBtn {
  align-self: center;
  min-width: 12rem;
}

@media (max-width: 768px) {
  .wrapper {
    padding: 0 0 2vh 0;
  }

  .priceListBtn {
  }

  .servicesRow {
    margin: 5rem 6vw;
  }

  .serviceItem {
    padding-bottom: 2vh;
    width: clamp(12rem ,50vw , 18rem);
  
  }

  .serviceItemTextContainer {
    background-color: var(--secondary);
    padding: 2vh 1vw;
    height: clamp(10rem, 50%, 12rem);
  }

  .serviceImg {
    height: 20vh;
    border-top-left-radius: 100%;
    border-top-right-radius: 100%;
  }
}
