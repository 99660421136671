l.container {
  display: flex;
  flex-direction: column;
}

.wrapper {
  padding: 2vh 0;
  display: flex;
  justify-content: center;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.pricing {
  width: 56%;
  height: auto;
  margin: 0 0 2rem 0;
}

.wrapper h1 {
  color: var(--primary);
  padding: 1rem 0;
  font-weight: bold;
}

.footerStyle {
  background-color: var(--secondary);
}

.footerStyle a,
.footerStyle h3,
.footerStyle h2
 {
  white-space: pre-wrap;
  color: #000;
}


.footerStyle a:hover {
  color: #fff;
}

@media (max-width: 768px) {
  .container {
  }

  .wrapper {
    padding: 2vh 0;
  }

  .pricing {
    width: 100vw;
    height: auto;
    margin: 0 0 2rem 0;
  }
}
