.primaryBanner {
  display: flex;
  padding: 20vh clamp(1rem, 10vw, 20rem) 2vh;
  justify-content: space-between;
  margin-top: 0;
  background-image: url('../../assets/img/banner03.jpeg');
  background-size: cover; 
  background-position: center;
  height: 65vh; 
  flex-direction: column;
  position: relative;
}

.primaryBanner h1 {
  color:#fff;
  font-weight: bold;
  font-size: 3vw;
  z-index: 2;
  text-align: center;
  text-shadow: -1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000,  1px 1px 0 #000;
  }

.primaryBanner h2 {
  color:#fff;
  font-size: 2vw;
   z-index: 2;
   text-align: center;
   text-shadow: -1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000,  1px 1px 0 #000;
}

.socialIcon{
  width: 3vw;
  height: 3vw;
  margin: 0 1vw;
}

.rightArrow{
  transform: rotateY(180deg);
  width: 2vw;
  margin-left: 1vw;
}

.centeredContainer {
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
  margin: 0 0 0 23vw;
}

.mainLogo{
  width: 30vw;
  height: 30vw;
  max-width: 38rem;
  max-height: 38rem;
  position: absolute;
  top:5vh;
  left:5vw
}

@media (max-width: 768px) {
  .primaryBanner {
    padding: 18vh clamp(1rem, 10vw, 18rem) 2vh;
  }

  .primaryBanner h1 {
    color:#fff;
    font-weight: bold;
    font-size: 5vw;
    z-index: 2;
    margin: 5vh 0 0 0;
    }
  
  .primaryBanner h2 {
    color:#fff;
    font-size: 4vw;
  }

  .mainLogo{
    width: 20vh;
    height: 20vh;
    top:2vh;
    left:4vw
  }

  .socialIcon{
    width: 8vw;
    height: 8vw;
  }

  .rightArrow{
    width: 5vw;
    margin-left: 2vw;
  }

  .centeredContainer {
    margin: 0;
  }
}
