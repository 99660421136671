/* MobileHeader.module.css */
.mobileHeader {
  position: absolute;
  left: 0;
  padding: 2vh 0 0 1rem;
}

.menuButton {
  background: none;
  border: none;
  cursor: pointer;
  color: var(--primary);
  font-size: 4vw;
  font-weight: 600;
}

.dropdownContent {
  position: fixed;
  top: 0;
  left: 0;
  background-color: #fff;
  box-shadow: 0px 4px 8px rgba(122, 103, 103, 0.4);
  z-index: 10;
  opacity: 0;
  pointer-events: none; /* Prevent interactions when closed */
  min-width: 60vw;
  height: 100vh;
  border-radius: 0 5px 0 0;
}

/* Adjust the opacity when the menu is open */
.mobileHeader.open .dropdownContent {
  opacity: 1;
  pointer-events: auto;
  transition: opacity 0.3s ease-in-out, transform 0.3s ease;
  transform: translateX(0);
}

.dropdownContent ul {
  list-style: none;
  padding: 0 0;
  margin: 0.5vh 0;
}

.dropdownContent li {
  padding: 0.8rem 1rem;
  color: var(--primary);
  cursor: pointer;
  font-size: 5vw;
  font-weight: lighter;
}

.fadeOut {
  opacity: 0;
  transition: opacity 0.3s ease-in-out, transform 0.3s ease;
  transform: translateX(-100%);
}

.selectedItem {
  background-color: #000;
}

.creditFooter {
  background-color: rgb(234, 231, 231);
  justify-content: center;
  align-items: center;
  display: flex;
  padding: 12px 0;
  position: fixed;
  bottom: 0;
}

.creditFooter a,
.creditFooter h4 {
  margin: 0;
  font-size: 10px;
  color: #000;
  font-weight: lighter;
  white-space: pre-wrap;
}

.creditFooter a {
  text-decoration-line: underline;
}

.menuHeader {
  flex-direction: row;
  align-items: flex-end;
  justify-content: space-between;
  display: flex;
  padding: 2rem 1rem;
}

.menuHeader h2 {
  margin: 0;
  padding: 0;
  font-size: 24px;
}

.closeButton {
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}

@media (max-width: 768px) {
}
