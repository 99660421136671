.container {
}

.introduction {
  display: flex;
  flex-direction: row;
  background-color: #fff;
  justify-content: space-around;
  min-height: 20rem;
  align-items: center;
  position: relative;
}

.imageStyle {
  height: 30vw;
  width: 30vw;
  min-height: 26rem;
  min-width: 26rem;
  max-height: 28rem;
  max-width: 28rem;
  display: block;
  object-fit: cover;
  border-bottom-left-radius: 100%;
  border-bottom-right-radius: 100%;
  /* border: 1px solid black; */
  position: absolute;
  top: 0;
  right: 5vw;
}

.textContainer {
  margin: 3vw clamp(1rem, 50vw, 60rem) 0 2vw;
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
  z-index: 2;
}

.textContainer h1 {
  text-align: center;
}

.textContainer h4 {
  text-align: center;
  margin: 1vh 0 2vh 0;
}

.popularContainer {
  background-color: var(--secondary);
}

.contactStaffContainer {
  background-color: var(--background-color);
  justify-content: center;
  display: flex;
  padding: 5vh 0 0 0;
}

.wrapperStyling {
  padding: 8vh 6vw 9vh;
  background-color: var(--secondary);
  border-top-left-radius: 30rem;
  border-top-right-radius: 30rem;
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
}

.wrapperStyling h1,
.wrapperStyling h4 {
  text-align: center;
  max-width: 20rem;
}

.bookNowBtnStyle {
  background-color: var(--background-color);
}

@media (max-width: 768px) {
  .introduction {
  }

  .textContainer {
    margin: 0;
    justify-content: center;
    align-items: center;
    display: flex;
    flex-direction: column;
    z-index: 2;
  }

  .textContainer h1 {
    text-align: center;
    color: #fff;
    margin: 0 20vw;
    text-shadow: -1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000;
  }

  .textContainer h4 {
    text-align: center;
    margin: 1vh 18vw 2vh;
    color: #fff;
    text-shadow: -1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000;
    font-size: 0.8rem;
  }

  .imageStyle {
    height: 22rem;
    width: 22rem;
    min-height: auto;
    min-width: auto;
    transform: translateX(-50%);
    left: 50%;
    top: 0;
    right: auto;
    align-self: center;
  }
}
