.helloworld {
  background-color: var(--background-color);
}


.overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
  cursor: pointer;
}

.popup {
  border-radius: 8px;
  align-items: center;
  justify-content: center;
}

.popup img {
 height: 90vh;
}

@media (max-width: 768px) {
  .helloworld {
    padding: 0;
    max-width: 100%;
  }

  .topSeparator {
    background-color: var(--primary);
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 1vh 0;
  }

  .bookNowA {
    margin-right: 1rem;
    color: #fff;
    font-size: 14px;
  }

  .popup img {
    height: auto;
    width: 90vw;
   }
}
