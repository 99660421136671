.container {
  padding: 1rem 0;
  background-color: var(--secondary);
}

.feedContainer {
  display: flex;
  flex-direction: row;
  padding: 0 0;
  margin: 0 0 1rem 0;
  justify-content: center;
}

.feedImage {
  width: 20vw;
  height: 20vw;
  margin: 0 1rem 0 0;
  object-fit: cover;
  cursor: pointer;
}

.noMargin {
  margin: 0 0;
}

.instaIconContainer {
  cursor: pointer;
  display: flex;
  flex-direction: row;
  margin: 2vh 0 5vh 5vw;
  align-items: center;
}

.instaIcon {
  width: 3rem;
  height: 3rem;
}

.instaIconContainer h3 {
  color: #000;
  margin: 0 0 0 1rem;
  font-size: 2rem;
}

.instaIconSmall {
  width: 1.5rem;
  height: 1.5rem;
}

.followMeContainer {
  display: flex;
  flex-direction: row;
  border-radius: 5px;
  background-color: var(--primary);
  justify-content: center;
  align-items: center;
  height: 3rem;
  width: 18rem;
  margin: 2rem auto;
  cursor: pointer;
}

.followMeContainer h4 {
  color: #000;
  margin: 0 0 0 1rem;
  align-self: center;
  font-weight: 600;
  font-size: 1rem;
}

@media (max-width: 768px) {
  .instaIcon {
    width: 2rem;
    height: 2rem;
  }

  .feedContainer {
    margin: 0 0 0.5rem 0;
  }

  .followMeContainer {
    height: 3rem;
    width: 15rem;
  }

  .feedContainer {
    margin: 0 0 0.8rem 0;
    justify-content: space-evenly;
  }

  .feedImage {
    width: 45vw;
    height: 45vw;
    margin: 0 0 0 0;
  }

  .followMeContainer h4 {
    font-size: 14px;
  }

  .instaIconContainer {
    flex-direction: row;
    margin: 4vh 0 5vh 5vw;
  }
}
